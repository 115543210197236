<section class="how-work-area pt-100 pb-70 margin-top-20">
    <div class="container">
        <div class="section-title">
            <h2>How We <span>Work</span></h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-info-circle"></i>
                    </div>
                    <h3>Requirement Gathering</h3>
                    <p>The stepping stone before starting any project is the Information Gathering Phase. Here we try to
                        understand your requirements from your websites development. This information is used to plan
                        the strategy for designing and development.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-cogs"></i>
                    </div>
                    <h3>Planing</h3>
                    <p>This phase mainly consists of finding the best style of design for your needs according to the
                        information and requirement provided by you.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-drafting-compass"></i>
                    </div>
                    <h3>Designing</h3>
                    <p>Here we actually start giving shape to your dreams and ideas. We create the
                        basic structure of the web page and show them to you for your final approval</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-file-code"></i>
                    </div>
                    <h3>Development</h3>
                    <p>This is the most time consuming phase as our web developers completes the web pages with proper
                        codes, contents, images, forms, databases etc. according to your needs.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fab fa-sketch"></i>
                    </div>
                    <h3>Product Launch</h3>
                    <p>We test the entire code, graphics, links, forms, databases, etc. We also troubleshoot any problem
                        faced to ensure that the website delivered by us is flawless and completely optimized.
                        After successful testing, and customers approvals, the product is delivered/deployed to the
                        customer for their use.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-user-cog"></i>
                    </div>
                    <h3>Maintenance</h3>
                    <p>We are always there for you even after completing the project so that we can help you with any
                        kind of structural
                        changes or content update required by you on your website.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>