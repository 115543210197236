<section class="who-we-are-area pt-100 pb-70 margin-top-20">
    <div class="container">
        <div class="section-title">
            <h2>Corporate <span>Videos</span></h2>
            <div class="servicesTagLine">
                <span>MOTION GRAPHICS </span> / <span>CORPORATE VIDEOS</span> / <span> PRODUCT PROMOTION VIDEOS </span>
                /
                <span>ANIMATION VIDEOS</span>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="strategy-content">
                    <div class="section-title">
                        <span class="sectionText">
                            Motion graphic designers that create visually captivating animated graphics that tell
                            stories, promote products and deliver compelling visual content. Motion graphics animation
                            is great for creating sales and marketing content, training and educational videos, and
                            types of video content.
                        </span>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="section-title">
                                <h4>Motion <span>Graphics Videos</span></h4>
                            </div>
                            <div class="about-content">
                                <p>Motion graphic designers that create visually captivating animated graphics that tell
                                    stories, promote products and deliver compelling visual content. Motion graphics
                                    animation is great for creating sales and marketing content, training and
                                    educational videos, and types of video content.</p>
                                <div class="row">
                                    <a href="/contact" class="btn btn-primary servicesContactUs">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="about-video">
                                <img src="assets/img/motion-graphics.jpg"
                                    alt="about">
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="about-video">
                                <img src="assets/img/product-promotion-videos.jpg" alt="about">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 mt-2">
                            <div class="section-title">
                                <h4>Product <span>Promotion Videos</span></h4>
                            </div>
                            <div class="about-content">
                                <p>Motion design production involves a number of steps that include concept development,
                                    script writing, storyboarding, illustration, and animation. Our videos use a
                                    combination of 2D and 3D animation, which helps boost the
                                    production value of the videos we make.</p><br />

                                <p>Our team consists of highly talented motion graphics designers with
                                    decades of industry experience. We’re ready to help you produce dynamic video
                                    content with motion branding using this and other animation styles.</p>
                                <div class="row">
                                    <a href="/contact" class="btn btn-primary servicesContactUs">Contact Us</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
    </div>
</section>