import { Component, OnInit } from '@angular/core';
import { HomePageService } from '../../../services/checkHomePage.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private _homePageService: HomePageService,
    private _title: Title,
    private _metaService: Meta) {
  }

  ngOnInit() {
    this._homePageService.setIsHomePage(false);

    this._title.setTitle("NGraphica - Pune's Top Logo Design, Brochure, Profile Design Company");

    this._metaService.addTag({
      name: 'keywords',
      content: 'Company Presentation, digital campaigns, digital strategy, social media communities, Marketing Plan, Corporate Branding, Digital Marketing, Social Media Marketing, Search Engine Optimization, Print Media, Corporate Identity, Leaflet, Flyer Designing, Logo Designer, Logo Design in pune, Best Logo Designer'
    })
    this._metaService.addTag({
      name: 'description',
      content: 'NGraphica provides solutions like branding, corporate logo design, brochure, advertising, leaflet, packaging design, graphic designing, corporate presentation/profile.'
    })
    this._metaService.addTag({
      name: 'author',
      content: 'NGraphica'
    })
  }

}
