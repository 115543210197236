<section class="who-we-are-area pt-100 pb-70 margin-top-20">
    <div class="container">
        <div class="section-title">
            <h2>Print <span>Media</span></h2>
            <div class="servicesTagLine">
                <span>BROCHURE DESIGN</span> / <span> LEAFLET DESIGN </span> /
                <span>PRODUCT CATALOGUE </span> / <span>CORPORATE PROFILE</span>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="strategy-content">
                    <div class="section-title">
                        <!-- <div class="row" style="margin-bottom: 25px;">
                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-cog fa-3x" aria-hidden="true"></i>
                                    <h6>Brochure Design </h6>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-eye fa-3x" aria-hidden="true"></i>
                                    <h6>Leaflet Design</h6>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-book fa-3x" aria-hidden="true"></i>
                                    <h6>Product Catalogue</h6>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-cog fa-3x" aria-hidden="true"></i>
                                    <h6>Corporate Profile</h6>
                                </div>
                            </div>
                        </div> -->
                        <span class="sectionText">We are professional Brochure Designers in Pune, offering
                            premium custom logo design and Branding services to startups, small and medium size
                            businesses. We convey your company message, colors, fonts, logo, emotions & target audience
                            through our branding service. We create unique branding concept for you business. Your
                            branding is set you apart from the competition & allow your customers to differentiate from
                            your competitors.</span>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="section-title">
                                <h4>Brochure <span>Leaflet Design</span></h4>
                            </div>
                            <div class="about-content">
                                <p>To win over new clients and hold the current once, your promoting group needs solid
                                    weaponry. We at NGraphica, design
                                    them in the form of attractive and informative brochures and leaflets. A Brochure
                                    that is well-designed can go far in
                                    setting up a strong image for your brand. And with such credibility, your business
                                    can't resist the opportunity to
                                    develop. Having a strong image allows you to request more expensive rates for your
                                    Products and Services in the market,
                                    and people are often ready to pay since you have a decent, a good and established
                                    Image.</p>
                                <div class="row">
                                    <a href="/contact" class="btn btn-primary servicesContactUs">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="about-video">
                                <img src="assets/img/brochure-design.jpg" alt="about">
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="about-video">
                                <img src="assets/img/corporate-profile.jpg" alt="about">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="section-title">
                                <h4>Corporate <span>Profile</span></h4>
                            </div>
                            <div class="about-content">
                                <p>A Perfect Corporate Profile to give it new wings to fly high, We design your new
                                    identity and a brand new face. The
                                    Corporate Profile of your association encourages you to stand out from the crowd and
                                    spread your message in the most
                                    refined and comprehensible manner as possible. Our Corporate Profile design attracts
                                    the targeted audience for your
                                    business. and helps to Conveying an understanding of the Products/Services and
                                    gaining popularity.</p>
                                <div class="row">
                                    <a href="/contact" class="btn btn-primary servicesContactUs">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
    </div>
</section>