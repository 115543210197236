import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-environmental-graphics',
  templateUrl: './environmental-graphics.component.html',
  styleUrls: ['./environmental-graphics.component.scss']
})
export class EnvironmentalGraphicsComponent implements OnInit {

  constructor(private _title: Title,
    private _metaService: Meta) {
  }

  ngOnInit() {
    this._title.setTitle("Best Environment Graphics Design Company in Pune - NGraphica");

    this._metaService.addTag({
      name: 'keywords',
      content: 'Environment graphics, environment graphic design, environment graphic design portfolio, art and illustration for graphic design, wayfinding design, Environmental Graphic Design (EGD), environment graphic design company pune'
    })
    this._metaService.addTag({
      name: 'description',
      content: 'Best and innovative Environment Graphic Design (EGD) company in Pune. Graphic design including, architectural, interior, landscape, and industrial design, etc.'
    })
    this._metaService.addTag({
      name: 'author',
      content: 'NGraphica'
    })
  }

}
