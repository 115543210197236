import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomePageService } from '../../../services/checkHomePage.service';
import { MenuItem, MenuRouteName } from '../../../models/menuItem';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isSticky: boolean = false;
  navigationMenus = ["home", "about", "services", "how we work", "team", "contact"]
  selectedItem;

  constructor(private router: Router, private _homePageService: HomePageService,
    private _title: Title,
    private _metaService: Meta) {
  }

  ngOnInit() {
    this._homePageService.isHomePage.subscribe(resp => {
      if (resp) {
        this.isSticky = resp;
      }
    })
  }

  listClick(event, newValue) {
    console.log(newValue);
    this.selectedItem = newValue;  // don't forget to update the model here
    // ... do other stuff here ...
  }



}
