<section id="work" class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Work</span></h2>
        </div>

        <!-- <div class="shorting-menu">
            <button class="filter" data-filter="all">All</button>
            <button class="filter" data-filter=".brand">Brand</button>
            <button class="filter" data-filter=".design">Design</button>
            <button class="filter" data-filter=".graphic">Graphic</button>
            <button class="filter" data-filter=".photoshop">Photoshop</button>
            <button class="filter" data-filter=".illustrator">Illustrator</button>
        </div> -->
    </div>

    <div class="container-fluid">
        <div class="shorting">
            <div class="row">
                <div class="col-lg-4 col-md-6 mix brand design">
                    <div class="single-work">
                        <img src="assets/portfolio/work-img1.jpg" alt="work-img" class="our-work-images">

                        <div class="work-content">
                            <h3>Brand Design</h3>
                            <ul>
                                <li><a href="javascript: void(0)">Brand</a></li>
                                <li>.</li>
                                <li><a href="javascript: void(0)">Design</a></li>
                            </ul>
                        </div>

                        <a href="assets/portfolio/work-img1.jpg" class="popup-btn"><i class="fas fa-eye"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix design graphic">
                    <div class="single-work">
                        <img src="assets/portfolio/work-img2.jpg" alt="work-img" class="our-work-images">

                        <div class="work-content">
                            <h3>Brand Design</h3>
                            <ul>
                                <li><a href="javascript: void(0)">Brand</a></li>
                                <li>.</li>
                                <li><a href="javascript: void(0)">Design</a></li>
                            </ul>
                        </div>

                        <a href="assets/portfolio/work-img2.jpg" class="popup-btn"><i class="fas fa-eye"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix graphic photoshop">
                    <div class="single-work">
                        <img src="assets/portfolio/work-img3.jpg" alt="work-img" class="our-work-images">

                        <div class="work-content">
                            <h3>Brand Design</h3>
                            <ul>
                                <li><a href="javascript: void(0)">Brand</a></li>
                                <li>.</li>
                                <li><a href="javascript: void(0)">Design</a></li>
                            </ul>
                        </div>

                        <a href="assets/portfolio/work-img3.jpg" class="popup-btn"><i class="fas fa-eye"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix photoshop illustrator">
                    <div class="single-work">
                        <img src="assets/portfolio/work-img4.jpg" alt="work-img" class="our-work-images">

                        <div class="work-content">
                            <h3>Brand Design</h3>
                            <ul>
                                <li><a href="javascript: void(0)">Brand</a></li>
                                <li>.</li>
                                <li><a href="javascript: void(0)">Design</a></li>
                            </ul>
                        </div>

                        <a href="assets/portfolio/work-img4.jpg" class="popup-btn"><i class="fas fa-eye"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix illustrator">
                    <div class="single-work">
                        <img src="assets/portfolio/work-img5.jpg" alt="work-img" class="our-work-images">

                        <div class="work-content">
                            <h3>Brand Design</h3>
                            <ul>
                                <li><a href="javascript: void(0)">Brand</a></li>
                                <li>.</li>
                                <li><a href="javascript: void(0)">Design</a></li>
                            </ul>
                        </div>

                        <a href="assets/portfolio/work-img5.jpg" class="popup-btn"><i class="fas fa-eye"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix brand">
                    <div class="single-work">
                        <img src="assets/portfolio/work-img6.jpg" alt="work-img" class="our-work-images">

                        <div class="work-content">
                            <h3>Brand Design</h3>
                            <ul>
                                <li><a href="javascript: void(0)">Brand</a></li>
                                <li>.</li>
                                <li><a href="javascript: void(0)">Design</a></li>
                            </ul>
                        </div>

                        <a href="assets/portfolio/work-img6.jpg" class="popup-btn"><i class="fas fa-eye"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix brand design">
                    <div class="single-work">
                        <img src="assets/portfolio/work-img7.jpg" alt="work-img" class="our-work-images">

                        <div class="work-content">
                            <h3>Brand Design</h3>
                            <ul>
                                <li><a href="javascript: void(0)">Brand</a></li>
                                <li>.</li>
                                <li><a href="javascript: void(0)">Design</a></li>
                            </ul>
                        </div>

                        <a href="assets/portfolio/work-img7.jpg" class="popup-btn"><i class="fas fa-eye"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix design graphic">
                    <div class="single-work">
                        <img src="assets/portfolio/work-img8.jpg" alt="work-img" class="our-work-images">

                        <div class="work-content">
                            <h3>Brand Design</h3>
                            <ul>
                                <li><a href="javascript: void(0)">Brand</a></li>
                                <li>.</li>
                                <li><a href="javascript: void(0)">Design</a></li>
                            </ul>
                        </div>

                        <a href="assets/portfolio/work-img8.jpg" class="popup-btn"><i class="fas fa-eye"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix graphic photoshop">
                    <div class="single-work">
                        <img src="assets/portfolio/work-img9.jpg" alt="work-img" class="our-work-images">

                        <div class="work-content">
                            <h3>Brand Design</h3>
                            <ul>
                                <li><a href="javascript: void(0)">Brand</a></li>
                                <li>.</li>
                                <li><a href="javascript: void(0)">Design</a></li>
                            </ul>
                        </div>

                        <a href="assets/portfolio/work-img9.jpg" class="popup-btn"><i class="fas fa-eye"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix photoshop illustrator">
                    <div class="single-work">
                        <img src="assets/portfolio/work-img10.jpg" alt="work-img" class="our-work-images">

                        <div class="work-content">
                            <h3>Brand Design</h3>
                            <ul>
                                <li><a href="javascript: void(0)">Brand</a></li>
                                <li>.</li>
                                <li><a href="javascript: void(0)">Design</a></li>
                            </ul>
                        </div>

                        <a href="assets/portfolio/work-img10.jpg" class="popup-btn"><i class="fas fa-eye"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix illustrator">
                    <div class="single-work">
                        <img src="assets/portfolio/work-img11.jpg" alt="work-img" class="our-work-images">

                        <div class="work-content">
                            <h3>Brand Design</h3>
                            <ul>
                                <li><a href="javascript: void(0)">Brand</a></li>
                                <li>.</li>
                                <li><a href="javascript: void(0)">Design</a></li>
                            </ul>
                        </div>

                        <a href="assets/portfolio/work-img11.jpg" class="popup-btn"><i class="fas fa-eye"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix brand design">
                    <div class="single-work">
                        <img src="assets/portfolio/work-img12.jpg" alt="work-img" class="our-work-images">

                        <div class="work-content">
                            <h3>Brand Design</h3>
                            <ul>
                                <li><a href="javascript: void(0)">Brand</a></li>
                                <li>.</li>
                                <li><a href="javascript: void(0)">Design</a></li>
                            </ul>
                        </div>

                        <a href="assets/portfolio/work-img12.jpg" class="popup-btn"><i class="fas fa-eye"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix design graphic">
                    <div class="single-work">
                        <img src="assets/portfolio/work-img13.jpg" alt="work-img" class="our-work-images">

                        <div class="work-content">
                            <h3>Brand Design</h3>
                            <ul>
                                <li><a href="javascript: void(0)">Brand</a></li>
                                <li>.</li>
                                <li><a href="javascript: void(0)">Design</a></li>
                            </ul>
                        </div>

                        <a href="assets/portfolio/work-img13.jpg" class="popup-btn"><i class="fas fa-eye"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix graphic photoshop">
                    <div class="single-work">
                        <img src="assets/portfolio/work-img14.jpg" alt="work-img" class="our-work-images">

                        <div class="work-content">
                            <h3>Brand Design</h3>
                            <ul>
                                <li><a href="javascript: void(0)">Brand</a></li>
                                <li>.</li>
                                <li><a href="javascript: void(0)">Design</a></li>
                            </ul>
                        </div>

                        <a href="assets/portfolio/work-img14.jpg" class="popup-btn"><i class="fas fa-eye"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix photoshop illustrator">
                    <div class="single-work">
                        <img src="assets/portfolio/work-img15.jpg" alt="work-img" class="our-work-images">

                        <div class="work-content">
                            <h3>Brand Design</h3>
                            <ul>
                                <li><a href="javascript: void(0)">Brand</a></li>
                                <li>.</li>
                                <li><a href="javascript: void(0)">Design</a></li>
                            </ul>
                        </div>

                        <a href="assets/portfolio/work-img15.jpg" class="popup-btn"><i class="fas fa-eye"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix illustrator">
                    <div class="single-work">
                        <img src="assets/portfolio/work-img16.jpg" alt="work-img" class="our-work-images">

                        <div class="work-content">
                            <h3>Brand Design</h3>
                            <ul>
                                <li><a href="javascript: void(0)">Brand</a></li>
                                <li>.</li>
                                <li><a href="javascript: void(0)">Design</a></li>
                            </ul>
                        </div>

                        <a href="assets/portfolio/work-img16.jpg" class="popup-btn"><i class="fas fa-eye"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>