<section class="who-we-are-area pt-100 pb-70 margin-top-20">
    <div class="container">
        <div class="section-title">
            <h2>Corporate <span>Branding</span></h2>
            <div class="servicesTagLine">
                <span>BRAND LOGO DESIGN</span> / <span> STATIONARY DESIGN </span> /
                <span>CORPORATE GUIDELINE MANUAL</span> / <span>CORPORATE IDENTITY DESIGN</span>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="strategy-content">
                    <div class="section-title">
                        <!-- <div class="row" style="margin-bottom: 25px;">
                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-cog fa-3x" aria-hidden="true"></i>
                                    <h6>Corporate Identity Design </h6>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-eye fa-3x" aria-hidden="true"></i>
                                    <h6>Brand Logo Design</h6>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-book fa-3x" aria-hidden="true"></i>
                                    <h6>Stationary Design</h6>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-cog fa-3x" aria-hidden="true"></i>
                                    <h6>Corporate Guidelines Manual</h6>
                                </div>
                            </div>
                        </div> -->
                        <span class="sectionText">
                            Standing out in a crowded marketplace is a business requirement for increased sales, profits
                            and success. A basic tenant
                            of marketing is the establishment of a brand identity for products and services. Branding
                            needs are not limited solely
                            to the marketing of products. Corporate branding helps customers relate to a business and
                            identify a wide range of
                            product offers over time. Effective branding minimizes the need for large marketing
                            initiates for every new product as
                            the consumer has a pre-set understanding of the product’s quality, reliability and purpose.
                        </span>
                        <!-- <span class="sectionText">We are professional Logo and Brochure Designers in Pune, offering
                            premium custom logo design and Branding services to startups, small and medium size
                            businesses. We convey your company message, colors, fonts, logo, emotions & target audience
                            through our branding service. We create unique branding concept for you business. Your
                            branding is set you apart from the competition & allow your customers to differentiate from
                            your competitors.</span> -->
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="section-title">
                                <h4>Strong <span>Corporate Identity</span></h4>
                            </div>
                            <div class="about-content">
                                <p>A perfect Corporate Identity is fundamental for each business association as it helps
                                    the connection of the clients and
                                    the organization. Clients typically get extraordinary assistance in understanding
                                    the business with the assistance of
                                    its Corporate Identity. It incorporates a few supporting gadgets e.g.Organization
                                    Letterhead, Business cards, Websites
                                    and other Digital sources. It makes a solitary and clear visual character for an
                                    organization.</p>
                                <div class="row">
                                    <a href="/contact" class="btn btn-primary servicesContactUs">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="about-video">
                                <img src="assets/img/design-your-professional-business-card-letterhead-stationery.jpg"
                                    alt="about">
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="about-video">
                                <img src="assets/img/brand-logo-design.jpg" alt="about">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="section-title">
                                <h4>Brand <span>Logo Design</span></h4>
                            </div>
                            <div class="about-content">
                                <p>To stand strong in the world of brands, you have to build up a specific identity, to
                                    disclose to them your identity and a big motivator for you. We, NGraphica the best
                                    Logo design company in Pune take the onus of this task by creating your unique
                                    corporate identity. It includes designing the logo, determining a positioning
                                    statement and conceptualizing the stationary.</p>
                                <div class="row">
                                    <a href="/contact" class="btn btn-primary servicesContactUs">Contact Us</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row" style="margin-top: 25px;">
            <div class="col-lg-12 col-md-12">
                <div class="row align-items-center">
                    <div class="about-content">
                        <span class="ngraphica-features-list-title">
                            <i class="fa fa-check ngraphica-features-list-check-mark"></i>
                            PROFFESIONAL LOGO & BRANDING</span>
                        <p class="listItem">We consistently produce Proffesional, high quality, spectacular Logo & Brand
                            designs for your business.</p>
                        <span class="ngraphica-features-list-title">
                            <i class="fa fa-check ngraphica-features-list-check-mark"></i>
                            SATISFACTION & ON-TIME DELIVERY</span>
                        <p class="listItem">Our promise is to deliver the design on-time, we involve our customer while
                            creating design that makes you satisfied and design is delivery is fast.</p>
                        <span class="ngraphica-features-list-title">
                            <i class="fa fa-check ngraphica-features-list-check-mark"></i>
                            UNIQUE CONCEPT</span>
                        <p class="listItem">We create unique logo & Brand which look can give your customer confidence
                            in
                            your service by making your business seem well-established and reliable.</p>
                        <span class="ngraphica-features-list-title">
                            <i class="fa fa-check ngraphica-features-list-check-mark"></i>
                            COMPATIBLE DESIGN FILES</span>
                        <p class="listItem">We create delivery which should be compatible digitally as well as
                            physically.
                            Our design is vector which can be printable on any size.</p>
                        <span class="ngraphica-features-list-title">
                            <i class="fa fa-check ngraphica-features-list-check-mark"></i>
                            UNLIMITED REVISIONS</span>
                        <p class="listItem">As you invest, we provide unlimited version of revisions untill and unless
                            you
                            are not satisfied.</p>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
    </div>
</section>