<footer class="footer-area" style="margin-top: 20px;">
    <div class="container">
        <div class="whatsapp_main">
            <a href="https://wa.me/+917020237795" target="_blank">
                <img src="./../../../assets/img/WhatsApp.svg" width="25%" class="whatsapp_img" />
            </a>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="cis-cont">
                    <div class="cis-icon">
                        <i class="fas fa-map-marked-alt"></i>
                    </div>
                    <div class="cis-text">
                        <h3 class="footerTitle">ADDRESS</h3>
                        <p>
                            <strong>N Graphica</strong>
                            <br>
                            Pune. <br>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="cis-cont">
                    <div class="cis-icon">
                        <i class="fas fa-envelope"></i>
                    </div>
                    <div class="cis-text">
                        <h3 class="footerTitle">EMAIL</h3>
                        <a href="mailto:ngraphica23@gmail.com">
                            <p style="margin-top: 5px;">ngraphica23@gmail.com</p>
                        </a>
                        <!-- <a href="mailto:ngraphica23@gmail.com">
                            <p style="margin-top: 5px;">ngraphica23@gmail.com</p>
                        </a> -->
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="cis-cont">
                    <div class="cis-icon">
                        <i class="fas fa-file"></i>
                    </div>
                    <div class="cis-text">
                        <h3 class="footerTitle">Policies & Terms</h3>
                        <a href="/privacy-policy">
                            <p style="margin-top: 5px;">Privacy Policy</p>
                        </a>
                        <a href="/terms">
                            <p style="margin-top: 5px;">Terms & Coniditons</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="footerTitle text-center">
        <div class="container">
            <!-- Social Links -->
            <div class="footer-soc-a">
                <!-- <a class="footerTitle" href="https://www.facebook.com/ngraphica" title="Facebook" target="_blank"><i
                        class="fab fa-facebook-f"></i></a>
                <a class="footerTitle" href="https://www.linkedin.com/company/13406699/" title="LinkedIn+"
                    target="_blank"><i class="fab fa-linkedin"></i></a>
                <a class="footerTitle" href="https://twitter.com/ngraphica" title="Twitter" target="_blank"><i
                        class="fab fa-twitter"></i></a>
                <a class="footerTitle" href="https://www.instagram.com/ngraphica/" title="Instagram" target="_blank"><i
                        class="fab fa-instagram"></i></a> -->
                <a class="footerTitle" href="https://www.facebook.com/neha.ngraphica" target="_blank"
                    title="Facebook"><i class="fab fa-facebook-f"></i></a>
                <!-- <a class="footerTitle" href="javascript:;" title="LinkedIn+"><i class="fab fa-linkedin"></i></a>
                <a class="footerTitle" href="javascript:;" title="Twitter"><i class="fab fa-twitter"></i></a> -->
                <a class="footerTitle" href="https://instagram.com/ngraphica?igshid=2rfbb4cv2mtp" target="_blank"
                    title="Instagram"><i class="fab fa-instagram"></i></a>
            </div>
            <!-- Copyright -->
            <div class="footer-copy">
                <a href="/home" class="footerTitle" target="_blank">&copy; N Graphica 2023</a>
                <p class="disclaimerText">Disclaimer: The agency makes no claim that all the artworks (with the image
                    and content) shown on this website are published works as some of the artworks were developed as
                    options to the approved works while a few others are mock-ups. </p>
            </div>
        </div>
    </footer>

</footer>
<div class="go-top"><i class="fas fa-chevron-up"></i></div>