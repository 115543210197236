import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  gtag: any;



  constructor(private _title: Title,
    private _metaService: Meta,
    private router: Router) {
    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      this.gtag('config', 'UA-182356075-1', { 'page_path': e.urlAfterRedirects });
    });



    this._title.setTitle("NGraphica - Best Graphic Design servcies company in Pune")

    this._metaService.addTag({
      name: 'keywords',
      content: 'Corporate Identity, Logo Design, Stationary Design, Corporate Guidelines Manual, Corporate Profile Design, Brochure / Leaflet / Flyer Design, Packaging Design, Product Catalogue Design, Website Design, Website Development, Static Website Design, Dynamic Website Development, Magazine Advertise Design, Newspaper Advertise Design, Hoardings Advertise Design, Office Environmental Branding, Showroom Environmental Branding, Signages, Exhibition Stall Design, Exhibition Panel Design, Exhibition Installation, Search Engine Optimization (SEO), Social Media Marketing (SMM), Search Engine Marketing (SEM), Corporate Presentations'
    })
    this._metaService.addTag({
      name: 'description',
      content: "Digital print media services is dedicated to providing multi-channels. Our print and publishing services help improve cross-media publishing through NGraphica."
    })
    this._metaService.addTag({
      name: 'author',
      content: 'NGraphica'
    })
  }

  ngOnInit() {
  }

}
