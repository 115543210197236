import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-print-media',
  templateUrl: './print-media.component.html',
  styleUrls: ['./print-media.component.scss']
})
export class PrintMediaComponent implements OnInit {

  constructor(private _title: Title,
    private _metaService: Meta) {
  }

  ngOnInit() {
    this._title.setTitle("Best Creative Brochure Design Company in Pune - NGraphica");

    this._metaService.addTag({
      name: 'keywords',
      content: 'Brochure Designing, Company Profile PPT, Leaflet Design Service in Pune, Flyer Design Service in Pune, Innovative Leaflet, Brochure Design Company in Pune, Leaflet Design Company in Pune, Product Catalogue Design Company in Pune, Brochure Design Company, Leaflet Design Company, Flyer Design Company, Product Catalogue Design'
    })
    this._metaService.addTag({
      name: 'description',
      content: 'An innovative brochure design is more than a bit of paper, that addresses the group of customers about your items and services. The great deal here for the design.'
    })
    this._metaService.addTag({
      name: 'author',
      content: 'NGraphica'
    })
  }

}
