import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonDirectivesModule } from '../app/shared/module/common.directives.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { WelcomeComponent } from './components/common/welcome/welcome.component';
import { AboutComponent } from './components/common/about/about.component';
import { StrategyComponent } from './components/common/strategy/strategy.component';
import { WhoWeAreComponent } from './components/common/who-we-are/who-we-are.component';
import { CorporateBrandingComponent } from './components/common/corporate-branding/corporate-branding.component';
import { CtaComponent } from './components/common/cta/cta.component';
import { SkillComponent } from './components/common/skill/skill.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { TeamComponent } from './components/common/team/team.component';
import { HowWeWorkComponent } from './components/common/how-we-work/how-we-work.component';
import { ServicesComponent } from './components/common/services/services.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { FaqComponent } from './components/common/faq/faq.component';
import { PricingComponent } from './components/common/pricing/pricing.component';
import { WorkComponent } from './components/common/work/work.component';
import { WhyWeDifferentComponent } from './components/common/why-we-different/why-we-different.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { ServicesTwoComponent } from './components/common/services-two/services-two.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { MapComponent } from './components/common/map/map.component';
import { PrintMediaComponent } from './components/common/print-media/print-media.component';
import { WebsiteDesignComponent } from './components/common/website-design/website-design.component';
import { AdvertisementCampaignsComponent } from './components/common/advertisement-campaigns/advertisement-campaigns.component';
import { EnvironmentalGraphicsComponent } from './components/common/environmental-graphics/environmental-graphics.component';
import { ExhibitionPanelDesignsComponent } from './components/common/exhibition-panel-designs/exhibition-panel-designs.component';
import { DigitalMarketingComponent } from './components/common/digital-marketing/digital-marketing.component';
import { CorporateVideosComponent } from './components/common/corporate-videos/corporate-videos.component';
import { CorporatePresentationsComponent } from './components/common/corporate-presentations/corporate-presentations.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { TncComponent } from './components/common/tnc/tnc.component';
import { PrivacyPolicyComponent } from './components/common/privacy-policy/privacy-policy.component'

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        HomeOneComponent,
        NavbarComponent,
        WelcomeComponent,
        AboutComponent,
        StrategyComponent,
        WhoWeAreComponent,
        CorporateBrandingComponent,
        CtaComponent,
        SkillComponent,
        FunfactsComponent,
        TeamComponent,
        HowWeWorkComponent,
        ServicesComponent,
        FooterComponent,
        SubscribeComponent,
        PartnerComponent,
        BlogComponent,
        FeedbackComponent,
        FaqComponent,
        PricingComponent,
        WorkComponent,
        WhyWeDifferentComponent,
        ContactComponent,
        ServicesTwoComponent,
        HomeElevenComponent,
        BlogDetailsComponent,
        MapComponent,
        PrintMediaComponent,
        WebsiteDesignComponent,
        AdvertisementCampaignsComponent,
        EnvironmentalGraphicsComponent,
        ExhibitionPanelDesignsComponent,
        DigitalMarketingComponent,
        CorporateVideosComponent,
        CorporatePresentationsComponent,
        TncComponent,
        PrivacyPolicyComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        GoogleMapsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CommonDirectivesModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }