<section id="team" class="team-area ptb-100 margin-top-20">
    <div class="container">
        <div class="section-title">
            <h4>Meet NGraphica</h4>
            <h2>Our Creative <span>Team</span></h2>
        </div>
        <div class="row">
            <div class="single-team-box" style="width: 350px;margin: 25px">
                <div class="image">
                    <img src="assets/img/CEO_Neha_Mulay.jpg" alt="team-img">
                </div>

                <div class="content">
                    <h3 class="title">Neha Mulay</h3>
                    <span class="post">Director & CEO</span>
                </div>

                <div class="social">
                    <ul>
                        <li><a href="javascript:;" class="fab fa-facebook-f"></a></li>
                        <li><a href="javascript:;" class="fab fa-twitter"></a></li>
                        <li><a href="javascript:;" class="fab fa-instagram"></a></li>
                        <li><a href="javascript:;" class="fab fa-linkedin-in"></a></li>
                    </ul>
                </div>
            </div>
            <div class="single-team-box" style="width: 350px;margin: 25px;">
                <div class="image">
                    <img src="assets/img/gaurav_mulay_1.jpg" alt="team-img">
                </div>

                <div class="content">
                    <h3 class="title">Gaurav Mulay</h3>
                    <span class="post">Finance Manager</span>
                </div>

                <div class="social">
                    <ul>
                        <li><a href="javascript:;" class="fab fa-facebook-f"></a></li>
                        <li><a href="javascript:;" class="fab fa-twitter"></a></li>
                        <li><a href="javascript:;" class="fab fa-instagram"></a></li>
                        <li><a href="javascript:;" class="fab fa-linkedin-in"></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>