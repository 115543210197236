<div class="main-banner item-bg-one">
    <div class="creative-banner-three"></div>
    <div id="particles-js"></div>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h4>We Are Creative</h4>
                    <h1>We Are
                        <a href="" class="typewrite" data-period="2000"
                            data-type='[ "Professional.", "Passionate.", "Creative.", "Designer.", "Unique.", "Best.", "Support." ]'>
                            <span class="wrap"></span>
                        </a>
                    </h1>
                    <h3 class="tagLine">THINKING YOUR <span class="primaryFont">BUSINESS</span></h3>
                    <h5 class="tagLine">
                        <span>BRANDING</span> / <span> DESIGN </span> /
                        <span>MOTION GRAPHICS</span> / <span>DIGITAL MARKETING</span> / <span>WEBSITE DEVELOPMENT</span>
                    </h5>
                    <a href="/contact" class="btn btn-primary">Get Started</a>
                    <!-- <a href="#work" class="btn btn-primary view-work">View Work</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<app-welcome></app-welcome>

<!-- <app-about></app-about>

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<app-skill></app-skill>

<app-funfacts></app-funfacts>

<app-team></app-team>

<app-how-we-work></app-how-we-work>

<app-services></app-services>

<app-why-we-different></app-why-we-different>

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>

<app-feedback></app-feedback>

<app-blog></app-blog>

<app-partner></app-partner>

<app-subscribe></app-subscribe>

<app-contact></app-contact> -->