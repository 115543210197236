<section id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>Terms & Conditions @ NGraphica</h4>
            <h2>Welcome to <span>N</span>Graphica</h2>
            <p><span>BRANDING</span> / <span> DESIGN </span> /
                <span>DIGITAL MARKETING</span> / <span>WEBSITE DEVELOPMENT</span>
            </p>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h4 class="terms-and-conditions-section-title">Payment Terms</h4>

                <p>NGraphica Payment Terms are strictly 10 days from invoice date and these terms will
                    be enforced rigorously. Upon acceptance of a proposal for website development, graphic design or
                    programming work, we will issue an invoice for 50% of the total fee quoted, payable immediately and
                    before any work or drafts are delivered to the client. The balance of all fees for the project is
                    due within 10 days of receipt of final invoice.
                </p><br />

                <p>The Client will reimburse incidental expenses including transportation costs, express courier or
                    mailing costs, other fees. Other than initial consultation, time spent for meetings at clients
                    request is billed at hourly rate, travel time billed at 1/2 hourly rate. An invoice will be issued:

                </p>
                <ul class="payment-terms-items">
                    <li>
                        <p>upon client acceptance of terms of project proposal (50% deposit) and</p>
                    </li>
                    <li>
                        <p>upon completion of contract (remainder plus any additional unquoted work)</p>
                    </li>
                    <li>
                        <p>its not our duty to take cheque/payment from your side, you should to deposit in our account.
                        </p>
                    </li>
                </ul>
                <br />

                <p>Any deposit paid to us and/or delivery times promised for work quoted is forfeit in its entirety if a
                    period of 90 or days or more elapses in which the project does not move forwards due to insufficient
                    or no contact from the client for whatsoever reason, whether within the clients control or not.
                </p><br />

                <p>It is the clients responsibility to deliver material (e.g. copy, images) required to complete any
                    work quoted for unless we have expressly and in writing undertaken to source any such material on
                    our own account and we will take no responsibility whatsoever for any delays or forfeits caused by
                    such material not being delivered. Please note it is not our policy to accept putting projects on
                    hold and the client should take steps to ensure that sufficient means are available to complete a
                    project once a contract is entered into.
                </p><br />

                <p>Deposit invoices for accepted proposals are payable immediately, and commencement of a contract
                    proper cannot take place until such deposit is received and cleared in our account. Credit terms on
                    final or intermediate invoices are strictly 10 days on invoice date. We reserves the right to impose
                    an interest surcharge of 5% over bank base rates on any balances outstanding after 14 days. All
                    amounts due to us shall be paid in full immediately when due without any deductions in respect of
                    any claim, counterclaim or set-off. We reserve the right to terminate without notice the service of
                    any client who is in breach of these clear and unambiguous payment terms.
                </p><br />

                <h4 class="terms-and-conditions-section-title">Delivery</h4>

                <p>As NGraphica only offers remote services, where a System Administrators time is the only real
                    product, there are no "Delivery Charges". If a job requires travel, this will be agreed before hand
                    and appropriate expenses included in the quote for work. (Mostly, all support are given online).
                </p><br />

                <h4 class="terms-and-conditions-section-title">Refunds</h4>

                <p>As web development is a task of time, where the finished product is a provisioned and deployed, once
                    NGraphica has started on a task we cannot offer a refund.
                </p><br />

                <p>However, if NGraphica is unable to complete the task due to problems on our end, we will first
                    communicate with the client to work out the best solution and, if appropriate, we will offer a full
                    refund if we have only spent a short amount of time on the task as a whole. It is our aim to always
                    keep communication channels open so as to avoid these scenarios completely.
                </p><br />

                <p>If you have a recurring service with NGraphica, all charges are paid in advance during the billing
                    cycle and are non-refundable. For billing cycles of 1 month or longer, and where a refund has been
                    requested, please see below regarding cancellation.
                </p><br />

                <h4 class="terms-and-conditions-section-title">Returns</h4>

                <p>NGraphica does not offer a returns policy due to the fact that our work is based on Time, and due to
                    the laws of the universe, we cannot currently return time (that is impossible!)
                </p><br />

                <h4 class="terms-and-conditions-section-title">Cancellations</h4>

                <p>We appreciate that sometimes clients make mistakes when ordering services, or may change their mind
                    before we start working on the project. If this is the case, simply contact us at the soonest
                    possible instance before we commence work, and we will gladly cancel the service and offer a full
                    refund. If we have already started and are a considerable way into the task, we reserve the right to
                    request payment for at least the time spent working on the project.
                </p><br />

                <p>NGraphica reserves the right to charge a cancellation fee on projects of $100 or above if cancelled
                    with less than 48 hours before NGraphica is due to commence work, this will generally be no more
                    than 30% of originally agreed invoice or the forfeit of the initial 50% upfront fee if that was
                    paid.
                </p><br />

                <p>If you have a service that is recurring with NGraphica and wish to cancel it, please contact us to
                    let us know at least 7 days before your next billing date and we can terminate your services for
                    you. Please note that services will continue to operate until the next billing date if you have
                    cancelled, but you will not be billed again unless you have other services with us. Therefore, if
                    you cancel mid billing cycle you will get what you paid for, and you dont have to worry about
                    cancelling on an exact date to get the most out of the service.
                </p><br />

                <h4 class="terms-and-conditions-section-title">Account Termination</h4>

                <p>Normally no Accounts are terminated immediately a suspension notice period expires and are then set
                    for deletion within 48 hours. Once terminated your account ceases to exist. However, customers have
                    been able to resurrect accounts by transferring cleared funds (usually cash payments) into our
                    account before the close of the business day. In such instances we will charge a $25 Reconnection
                    Fee to temporarily remove termination from the account (however, it may be too late - if the account
                    has been deleted no reinstatement will be possible). The account will not be reinstated until this
                    fee is paid in full with cleared funds. Reinstatement of a Terminated account is neither a right nor
                    a contract option - it is carried out at the exclusive discretion of the Director on duty at the
                    time, and only then if the account has not been permanently deleted.
                </p><br />

                <p>Hosting accounts and domain names are charged for annually in advance and much of the fees for these
                    services are allocated to the administration of those services. If an account is closed before the
                    end of the year paid for there is therefore no entitlement to a pro-rata reimbursement of unused
                    time unless the account/domain name(s) has been paid for more than one year in advance in which case
                    there will be a reimbursement entitlement for any whole year that remains unused on the account.
                </p><br />

                <h4 class="terms-and-conditions-section-title">What Happens if Client DoNot Pay?</h4>

                <p>You will leave us with no choice but to terminate the account to which the invoice refers. You will
                    no longer be able to use our services irrespective to which the invoice refers. Any website or
                    uploaded documents that you have on the account will be unreachable with immediate effect. If your
                    website visitors find a link to your site in a search engine and click on that link they will arrive
                    at a blank page. Any FTP access that you had to your website will be closed. Once your account has
                    been terminated all data on that account (e.g. e-mail and/or website) is automatically removed and
                    cannot be restored(IMAP users please note).
                </p><br />

                <p>I want to discuss this - there must be some mistake !</p><br />

                <p>Before we Terminate we issue a Suspension Notice, we will have done everything reasonable and proper
                    for a professional business to recover monies owed to us by you. We have sent you invoices. We have
                    probably sent you copy invoices. We have sent you statements. Our accounts department has been
                    available by telephone, fax and e-mail to discuss any inconsistencies or irregularities that you
                    feel may exist in an invoice(s). But once we have issued a Termination Notice the time for
                    discussion is over. NGraphicas Payment Policy will be rigorously enforced and our advice to you is
                    to pay the outstanding invoice(s) without delay. If you feel there is something to discuss,
                    something which should have been discussed when the invoice(s) was originally issued then please
                    contact our accounts department once your debt has been cleared.
                </p><br />

                <h4 class="terms-and-conditions-section-title">After Suspension what to?</h4>

                <p>The outstanding balance needs to be paid for with cleared funds. If you decide to send a cheque do so
                    now - we will not be responsible for any postal or bank clearance delays in which case your account
                    may still be terminated. Therefore, to settle the outstanding balance without delay you can make an
                    immediate payment at your own bank, at any branch of SARASWAT Bank or by electronic bank transfer.
                </p><br />

                <p>This Terms & Conditions is only for Credit Control of Company, it does not include your services
                    terms. For every
                    client we have different service deployment policy depends on their services, please contact our
                    business representative for that.
                </p><br />

                <h4 class="terms-and-conditions-section-title">Further Information</h4>

                <p>For further information about our policies, or to request copies of your personal information held by
                    us, or to make a complaint about the handling of your personal information, please email us at
                    <a class="terms-and-coniditons-link" href="mailto:ngraphica23@gmail.com">ngraphica23@gmail.com</a>
                </p><br />

                <p>You may also contact us to correct any details in your personal information that we hold.</p><br />

                <p>Sincerely, </p>
                <p>NGraphica.com</p>
            </div>

        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</section>