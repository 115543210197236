<section id="about" class="about-area ptb-100 margin-top-20">
    <div class="container">
        <div class="section-title">
            <h2>About <span>N</span>Graphica</h2>
            <span class="secondaryFont"> NGraphica is a brand design agency based in Pune, India.
                Innovating and creating graphics, logos, packaging, architecture, advertising, websites, print media and
                interactive social media. </span>
            <span class="secondaryFont">A NGraphica is the brainchild of its Director & CEO Neha, who is
                passionate about the process of creative communication. She has the necessary expertise, exposure, and
                experience in the advertising industry and the right entrepreneurial temperament to successfully manage
                the demands of the business. We believe in providing customised communication strategies and solutions
                while ever improving and building on the last assignment. We have the people, the resources, the
                know-how, the equipments, the methods and most importantly the quest to prove ourselves again and
                again.</span>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>We Are Digital <span>Marketing & Branding</span></h2>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Creative Design</li>
                        <li><i class="fas fa-check"></i>Highy Creative Solutions</li>
                        <li><i class="fas fa-check"></i>Responsive Design</li>
                        <li><i class="fas fa-check"></i>Modern Design</li>
                    </ul>
                </div>
            </div>

            <!-- <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/CEO_Neha_Mulay.jpg"
                        style="text-align: center; margin-top: -50px;height: 350px;width: 300px;" alt="about">
                </div>
            </div> -->
        </div>
        
        <app-who-we-are></app-who-we-are>
        <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
    </div>
</section>