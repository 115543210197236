import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-digital-marketing',
  templateUrl: './digital-marketing.component.html',
  styleUrls: ['./digital-marketing.component.scss']
})
export class DigitalMarketingComponent implements OnInit {

  constructor(private _title: Title,
    private _metaService: Meta) {
  }

  ngOnInit() {
    this._title.setTitle("NGraphica - Digital marketing Company in Pune");

    this._metaService.addTag({
      name: 'keywords',
      content: 'seo company in Pune, Best seo company in Pune,social media company in Pune, Best social media company in Pune'
    })
    this._metaService.addTag({
      name: 'description',
      content: 'Our attractive and outstanding service always perform the proper manner of logo design service, our expertise in promoting the brand through the logo design.'
    })
    this._metaService.addTag({
      name: 'author',
      content: 'NGraphica'
    })
  }

}
