import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-corporate-branding',
  templateUrl: './corporate-branding.component.html',
  styleUrls: ['./corporate-branding.component.scss']
})
export class CorporateBrandingComponent implements OnInit {

  constructor(private _title: Title,
    private _metaService: Meta) {
  }

  ngOnInit() {
    this._title.setTitle("NGraphica - Best Logo Design Company in Pune");

    this._metaService.addTag({
      name: 'keywords',
      content: 'Logo Designer, Logo Design in Pune, Best Logo Designer, Best Logo Designing company in Pune, Logo Design Company in Pune, Stationary Design Company, Corporate Guidelines Manual, Corporate Profile Design, Corporate Identity, Attractive Logo design, innovative logo design, creative logo design'
    })
    this._metaService.addTag({
      name: 'description',
      content: 'Our attractive and outstanding service always perform the proper manner of logo design service, our expertise in promoting the brand through the logo design.'
    })
    this._metaService.addTag({
      name: 'author',
      content: 'NGraphica'
    })
  }

}
