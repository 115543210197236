import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-advertisement-campaigns',
  templateUrl: './advertisement-campaigns.component.html',
  styleUrls: ['./advertisement-campaigns.component.scss']
})
export class AdvertisementCampaignsComponent implements OnInit {

  constructor(private _title: Title,
    private _metaService: Meta) {
  }

  ngOnInit() {
    this._title.setTitle("Advertisement Campaign, Magzine, Hoarding Design Company in Pune - NGraphica");

    this._metaService.addTag({
      name: 'keywords',
      content: 'Effective Advertising Campaigns, advertisement campaign design service, Effective Advertising Ad Campaign, Advertisement Campaign Design, advertising campaign ideas, advertising campaign strategy, advertising campaign planning, design of advertisement, advertising design process, design an advertising campaign, graphic design ad campaign, Advertisement Design'
    })
    this._metaService.addTag({
      name: 'description',
      content: 'Get the effective advertising ad campaign and advertisement campaign design service from design lab, Our innovative way to design your perfect ad campaign.'
    })
    this._metaService.addTag({
      name: 'author',
      content: 'NGraphica'
    })
  }

}
