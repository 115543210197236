import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ContactUs } from '../../../models/contactUs';
import { Title, Meta } from '@angular/platform-browser';
import { HomePageService } from './../../../services/checkHomePage.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactInfoForm: FormGroup;
  showMessage: boolean = false;
  successMessage = "Your message sent successfully!";
  objContactUs: ContactUs;
  submitted = false;

  constructor(private _title: Title,
    private formBuilder: FormBuilder,
    private _metaService: Meta,
    private _homePageService: HomePageService) {
  }

  ngOnInit() {
    this._title.setTitle("NGraphica - Design Company in Pune-Contact Us");

    this._metaService.addTag({
      name: 'keywords',
      content: 'Creative Packaging Design, Product packaging design, Engineering Company brochure, Pharma Company brochure, Corporate Leaflet, Newpaper Leaflet, Marketing Strategy Consultancy, Media Planning, Brand Creation, Customised Web development, Direct marketing, niche market, branding name ideas, design studio'
    })
    this._metaService.addTag({
      name: 'description',
      content: 'Contact Us for any type of inquiry like Logo Design, brochure design, environmental graphic design, exhibition stall design, packaging, corporate branding.'
    })
    this._metaService.addTag({
      name: 'author',
      content: 'NGraphica'
    })

    this.contactInfoForm = this.formBuilder.group({
      FirstName: ['', [Validators.required, Validators.maxLength(100)]],
      LastName: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.email]],
      Phone: ['', [Validators.required, Validators.maxLength(10)]],
      Message: ['', [Validators.required, Validators.maxLength(950)]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.contactInfoForm.controls; }

  saveContactInfo() {
    this.submitted = true;
    if (this.contactInfoForm.invalid) {
      return;
    }
    if (this.contactInfoForm.valid) {
      this.assignValues();
      this._homePageService.sendInquiry(this.objContactUs).subscribe(resp => {
      });
      this.showMessage = true;
      this.resetContactInfo();
      setTimeout(() => {
        this.showMessage = false;
      }, 1200);
    }
  }


  assignValues() {
    this.objContactUs = new ContactUs();
    this.objContactUs.name = this.contactInfoForm.controls['FirstName'].value + ' ' + this.contactInfoForm.controls['LastName'].value;
    this.objContactUs.email = this.contactInfoForm.controls['email'].value;
    this.objContactUs.phone = this.contactInfoForm.controls['Phone'].value;
    this.objContactUs.desc = this.contactInfoForm.controls['Message'].value;
  }

  resetContactInfo() {
    this.submitted = false;
    this.contactInfoForm.reset();
  }

}
