import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/common/about/about.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { TeamComponent } from './components/common/team/team.component';
import { ServicesComponent } from './components/common/services/services.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { CorporateBrandingComponent } from './components/common/corporate-branding/corporate-branding.component';
import { PrintMediaComponent } from './components/common/print-media/print-media.component';
import { WebsiteDesignComponent } from './components/common/website-design/website-design.component';
import { AdvertisementCampaignsComponent } from './components/common/advertisement-campaigns/advertisement-campaigns.component';
import { EnvironmentalGraphicsComponent } from './components/common/environmental-graphics/environmental-graphics.component';
import { ExhibitionPanelDesignsComponent } from './components/common/exhibition-panel-designs/exhibition-panel-designs.component';
import { DigitalMarketingComponent } from './components/common/digital-marketing/digital-marketing.component';
import { CorporateVideosComponent } from './components/common/corporate-videos/corporate-videos.component';
import { CorporatePresentationsComponent } from './components/common/corporate-presentations/corporate-presentations.component';
import { HowWeWorkComponent } from './components/common/how-we-work/how-we-work.component';
import { WorkComponent } from './components/common/work/work.component';
import { TncComponent } from './components/common/tnc/tnc.component';
import { PrivacyPolicyComponent } from './components/common/privacy-policy/privacy-policy.component';

const routes: Routes = [
    { path: 'home', component: HomeElevenComponent },
    { path: 'about', component: AboutComponent },
    { path: 'team', component: TeamComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'work', component: WorkComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'how-we-work', component: HowWeWorkComponent },
    { path: 'corporate-branding', component: CorporateBrandingComponent },
    { path: 'print-media', component: PrintMediaComponent },
    { path: 'website-designs', component: WebsiteDesignComponent },
    { path: 'advertisement-campaigns', component: AdvertisementCampaignsComponent },
    { path: 'environmental-graphics', component: EnvironmentalGraphicsComponent },
    { path: 'exhibition-panel-designs', component: ExhibitionPanelDesignsComponent },
    { path: 'digital-marketing', component: DigitalMarketingComponent },
    { path: 'corporate-videos', component: CorporateVideosComponent },
    // { path: 'corporate-presentations', component: CorporatePresentationsComponent },
    { path: 'terms', component: TncComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    // { path: 'homeone', component: HomeOneComponent },
    { path: '', component: HomeElevenComponent },
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: '**', redirectTo: 'home', pathMatch: 'full' },
    //{ path: 'blog-details', component: BlogDetailsComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }