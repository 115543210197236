<section id="services" class="services-area pt-100 pb-70 margin-top-20">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Services</span></h2>
            <span class="sectionText">
                There's no limit to what you can get designed at NGraphica. Whether you're looking for a spectacular
                new logo or some stunning flyers, NGraphica can make it happen.
                NGraphica prides itself on it's high quality designers who deliver only the best graphic design
                services. Find the right design service for you below and get design you'll love today!!
            </span>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-box">
                    <div class="services-img">
                        <img src="assets/img/services/CorporateBranding.jpg" alt="services-img">
                    </div>

                    <div class="services-content">
                        <h3>Corporate Branding</h3>
                        <ul class="features-list" style="text-align: left;">
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Corporate Identity</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Logo Design</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Stationary Design</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Corporate Guidelines Manual</p>
                            </li>
                        </ul>
                        <a href="/corporate-branding" title="Read More" class="link-btn">
                            <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-box">
                    <div class="services-img">
                        <img src="assets/img/services/PrintMedia.jpg" alt="services-img">
                    </div>

                    <div class="services-content">
                        <h3>Print Media</h3>
                        <ul class="features-list" style="text-align: left;">
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Corporate Profile Design</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Brochure / Leaflet / Flyer Design</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Packaging Design</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Product Catalogue Design</p>
                            </li>
                        </ul>
                        <a href="/print-media" title="Read More" class="link-btn">
                            <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-box">
                    <div class="services-img">
                        <img src="assets/img/services/WebsiteDesign.jpg" alt="services-img">
                    </div>

                    <div class="services-content">
                        <h3>Website Design</h3>
                        <ul class="features-list" style="text-align: left;">
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Website Design & Development</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Static Website Development </p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Dynamic Website Development</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Website Support & Maintenance</p>
                            </li>
                        </ul>
                        <a href="/website-designs" title="Read More" class="link-btn">
                            <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-box">
                    <div class="services-img">
                        <img src="assets/img/services/AdvertisementCampaigns.jpg" alt="services-img">
                    </div>

                    <div class="services-content">
                        <h3>Advertisement Campaigns</h3>
                        <ul class="features-list" style="text-align: left;">
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Magazine Advertise Design</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Newspaper Advertise Design</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Hoardings Advertise Design</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Digital Ads</p>
                            </li>
                        </ul>
                        <a href="/advertisement-campaigns" title="Read More" class="link-btn">
                            <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-box">
                    <div class="services-img">
                        <img src="assets/img/services/EnvironmentalGraphics.jpg" alt="services-img">
                    </div>

                    <div class="services-content">
                        <h3>Environmental Graphics</h3>
                        <ul class="features-list" style="text-align: left;">
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Office Environmental Branding</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Showroom Environmental Branding</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Signages</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Way Findings</p>
                            </li>
                        </ul>
                        <a href="/environmental-graphics" title="Read More" class="link-btn">
                            <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-box">
                    <div class="services-img">
                        <img src="assets/img/services/ExhibitionPanelDesign.jpg" alt="services-img">
                    </div>

                    <div class="services-content">
                        <h3>Exhibition Panel Design</h3>
                        <ul class="features-list" style="text-align: left;">
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Exhibition Stall Design</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Exhibition Panel Design</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Exhibition Installation</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Standee Design</p>
                            </li>
                        </ul>
                        <a href="/exhibition-panel-designs" title="Read More" class="link-btn">
                            <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-box">
                    <div class="services-img">
                        <img src="assets/img/services/DigitalMarketing.jpg" alt="services-img">
                    </div>

                    <div class="services-content">
                        <h3>Digital Marketing</h3>
                        <ul class="features-list" style="text-align: left;">
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Search Engine Optimization (SEO)</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Social Media Marketing (SMM)</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Search Engine Marketing (SEM)</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Emailers & eFlyer</p>
                            </li>
                        </ul>
                        <a href="/digital-marketing" title="Read More" class="link-btn">
                            <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

             <div class="col-lg-4 col-md-6">
                <div class="single-box">
                    <div class="services-img">
                        <img src="assets/img/services/Videos.jpg" alt="services-img">
                    </div>

                    <div class="services-content">
                        <h3>Motion Graphcis & Video Editing</h3>
                        <ul class="features-list" style="text-align: left;">
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Corporate Videos</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Product Promotion Videos</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Animation Videos</p>
                            </li>
                            <li style="display: none;"><i class="fa fa-angle-right"></i>
                                <p class="services-points">Emailers & eFlyer</p>
                            </li>
                        </ul>
                        <a href="/corporate-videos" title="Read More" class="link-btn">
                            <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

           <!-- <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-box">
                    <div class="services-img">
                        <img src="assets/img/services/Presentations.jpg" alt="services-img">
                    </div>

                    <div class="services-content">
                        <h3>Presentations</h3>
                        <ul class="features-list" style="text-align: left;">
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Corporate Presentations</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Interactive Presentations</p>
                            </li>
                            <li><i class="fa fa-angle-right"></i>
                                <p class="services-points">Animated Presentations</p>
                            </li>
                            <li style="display: none;"><i class="fa fa-angle-right"></i>
                                <p class="services-points">Emailers & eFlyer</p>
                            </li>
                        </ul>
                        <a href="/corporate-presentations" title="Read More" class="link-btn">
                            <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div> -->
            
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</section>