<section class="who-we-are-area pt-100 pb-70 margin-top-20">
    <div class="container">
        <div class="section-title">
            <h2>Environmental <span>Graphics</span></h2>
            <div class="servicesTagLine">
                <span>OFFICE ENVIRONMENTAL BRANDING </span> / <span> SHOWROOM ENVIRONMENTAL BRANDING </span> /
                <span>SIGNAGES </span> / <span>WAYFINDINGS</span>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="strategy-content">
                    <div class="section-title">
                        <!-- <div class="row" style="margin-bottom: 25px;">
                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-cog fa-3x" aria-hidden="true"></i>
                                    <h6>Office Environmental Branding</h6>
                                </div>
                            </div>

                            <div class="col-md-4 col-sm-4 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-eye fa-3x" aria-hidden="true"></i>
                                    <h6>Showroom Environmental Branding</h6>
                                </div>
                            </div>

                            <div class="col-md-2 col-sm-2 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-book fa-3x" aria-hidden="true"></i>
                                    <h6>Signages</h6>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-cog fa-3x" aria-hidden="true"></i>
                                    <h6>WayFindings</h6>
                                </div>
                            </div>
                        </div> -->
                        <span class="sectionText">We are professional Logo and Brochure Designers in Pune, offering
                            premium custom logo design and Branding services to startups, small and medium size
                            businesses. We convey your company message, colors, fonts, logo, emotions & target audience
                            through our branding service. We create unique branding concept for you business. Your
                            branding is set you apart from the competition & allow your customers to differentiate from
                            your competitors.</span>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="section-title">
                                <h4>Environment <span>Graphics</span></h4>
                            </div>
                            <div class="about-content">
                                <p>Environment Graphic Design (EGD) is a vital part of your Identity. It helps with
                                    branding an environment. It involves disciplines such as graphic, architectural,
                                    interior, landscape, and industrial design which communicate identity and
                                    information.The workplace can be specially designed for keeping the brand in focus.
                                    The graphics on the walls can tell the brand story to all visitors. This type of
                                    designing is also called as visual branding. Environmental graphic design
                                    is intended to improve one’s experience by making locations more engaging,
                                    informative and navigable.</p>
                                <div class="row">
                                    <a href="/contact" class="btn btn-primary servicesContactUs">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="about-video">
                                <img src="assets/img/office-environmental-designs.jpg" alt="about">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="about-video">
                                <img src="assets/img/corporate-profile.jpg" alt="about">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="section-title">
                                <h4>Corporate <span>Profile</span></h4>
                            </div>
                            <div class="about-content">
                                <p>A Perfect Corporate Profile to give it new wings to fly high, We design your new
                                    identity and a brand new face. The
                                    Corporate Profile of your association encourages you to stand out from the crowd and
                                    spread your message in the most
                                    refined and comprehensible manner as possible. Our Corporate Profile design attracts
                                    the targeted audience for your
                                    business. and helps to Conveying an understanding of the Products/Services and
                                    gaining popularity.</p>
                                <div class="row">
                                    <a href="/contact">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
    </div>
</section>