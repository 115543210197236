<section class="who-we-are-area pt-100 pb-70 margin-top-20">
    <div class="container">
        <div class="section-title">
            <h2>Website <span>Design & Development</span></h2>
            <div class="servicesTagLine">
                <span>STATIC WEBSITE DESIGN & DEVELOPMENT </span> / <span> DYNAMIC WEBSITE DEVELOPMENT </span> /
                <span>WEBSITE SUPPORT & DEVELOPMENT </span>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="strategy-content">
                    <div class="section-title">
                        <!-- <div class="row" style="margin-bottom: 25px;">
                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-cog fa-3x" aria-hidden="true"></i>
                                    <h6>Search Engine Optimization</h6>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-eye fa-3x" aria-hidden="true"></i>
                                    <h6>Search Engine Marketing</h6>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-book fa-3x" aria-hidden="true"></i>
                                    <h6>Social Media Marketing</h6>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-cog fa-3x" aria-hidden="true"></i>
                                    <h6>Website Design & Development</h6>
                                </div>
                            </div>
                        </div> -->
                        <span class="sectionText">We are professional Logo and Brochure Designers in Pune, offering
                            premium custom logo design and Branding services to startups, small and medium size
                            businesses. We convey your company message, colors, fonts, logo, emotions & target audience
                            through our branding service. We create unique branding concept for you business. Your
                            branding is set you apart from the competition & allow your customers to differentiate from
                            your competitors.</span>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="section-title">
                                <h4>Website <span> Design & Development </span></h4>
                            </div>
                            <div class="about-content">
                                <p>We believe beauty shines from the inside out, and functionality is a beautiful thing.
                                    Our web design team develops
                                    websites with functionality at the core. Our websites solve users’ problems and keep
                                    them coming back. We pride
                                    ourselves on creating visually attractive websites that represent your brand and
                                    leave users with a positive lasting
                                    impression.
                                    Your website is your brand’s digital home – consider us your creative homebuilder
                                    and interior decorator.</p>
                                <div class="row">
                                    <a href="/contact" class="btn btn-primary servicesContactUs">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="about-video">
                                <img src="assets/img/website-design-development.jpg" alt="about">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="about-video">
                                <img src="assets/img/corporate-profile.jpg" alt="about">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="section-title">
                                <h4>Corporate <span>Profile</span></h4>
                            </div>
                            <div class="about-content">
                                <p>A Perfect Corporate Profile to give it new wings to fly high, We design your new
                                    identity and a brand new face. The
                                    Corporate Profile of your association encourages you to stand out from the crowd and
                                    spread your message in the most
                                    refined and comprehensible manner as possible. Our Corporate Profile design attracts
                                    the targeted audience for your
                                    business. and helps to Conveying an understanding of the Products/Services and
                                    gaining popularity.</p>
                                <div class="row">
                                    <a href="/contact">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
    </div>
</section>