<section class="who-we-are-area pt-100 pb-70 margin-top-20">
    <div class="container">
        <div class="section-title">
            <h2>Digital <span>Marketing</span></h2>
            <div class="servicesTagLine">
                <span>SEARCH ENGINE OPTIMIZATION </span> / <span> SEARCH ENGINE MARKETING </span> /
                <span>SOCIAL MEDIA MARKETING </span> / <span>WEBSITE DESIGN & DEVELOPMENT </span>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="strategy-content">
                    <div class="section-title">
                        <!-- <div class="row" style="margin-bottom: 25px;">
                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-cog fa-3x" aria-hidden="true"></i>
                                    <h6>Search Engine Optimization</h6>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-eye fa-3x" aria-hidden="true"></i>
                                    <h6>Search Engine Marketing</h6>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-book fa-3x" aria-hidden="true"></i>
                                    <h6>Social Media Marketing</h6>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-cog fa-3x" aria-hidden="true"></i>
                                    <h6>Website Design & Development</h6>
                                </div>
                            </div>
                        </div> -->
                        <span class="sectionText">We are professional Logo and Brochure Designers in Pune, offering
                            premium custom logo design and Branding services to startups, small and medium size
                            businesses. We convey your company message, colors, fonts, logo, emotions & target audience
                            through our branding service. We create unique branding concept for you business. Your
                            branding is set you apart from the competition & allow your customers to differentiate from
                            your competitors.</span>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="section-title">
                                <h4>Search <span> Engine Optimization </span></h4>
                            </div>
                            <div class="about-content">
                                <p>Today, search engines are on ‘Team User,’ focusing more on quality, useful content
                                    and the experience your website
                                    provides for its users.
                                    Google changes its search algorithms up to 600 times every year and considers
                                    hundreds of ranking factors (both on and
                                    off-site) when evaluating your website, you’ll want to make sure your focused on SEO
                                    at all times.</p>

                                <p>Most internet users begin their session by searching for something—that something is
                                    a need. People use the internet to
                                    fulfill their need for information, finding a local restaurant, perfect piece of
                                    clothing, or the ideal contractor for a home remodel.

                                    Search engine optimization (SEO) is the process of helping your customers connect
                                    with your business online.

                                    Our team uses modern SEO services, backed by the best digital practices, to increase
                                    your traffic & rankings for vetted
                                    keywords and long-tail phrases that drive business to your site.</p>
                                <div class="row">
                                    <a href="/contact" class="btn btn-primary servicesContactUs">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="about-video">
                                <img src="assets/img/seo.jpg" alt="about">
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="about-video">
                                <img src="assets/img/social-media.jpg" alt="about">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="section-title">
                                <h4>Social <span>Media Marketing</span></h4>
                            </div>
                            <div class="about-content">
                                <p>With more than 74% of all online adults using social networking sites, if you want to
                                    reach your customers on a human
                                    level, you have to join the party.

                                    Developing a targeted social media marketing strategy for your business will
                                    exponentially boost brand awareness and
                                    allow you to connect with and build relationships with the people who matter most to
                                    your business.

                                    At NGraphica, we’ve setup and maintained social media accounts,
                                    and are fully equipped to get you set
                                    up with a stellar social presence, too.</p>
                                <p>
                                    Facebook, Twitter, Instagram and many other networks are simply a part of daily life
                                    for millions – in fact, billions –
                                    of us. The ubiquitous use of Facebook extends to more than a quarter of the earths
                                    population- that’s more than 2
                                    billion active users.

                                    With such a far-reaching scope, it’s no wonder that these networks have become such
                                    a valuable tool for businesses. If
                                    you’re not posting on Facebook, trending on Twitter or sharing pictures on
                                    Instagram, you’re behind the times – but we
                                    can help.

                                    Effective social media marketing is about much more than just posting. Understanding
                                    proper optimization on social
                                    networks is a nuanced and delicate thing. What works for one brand may not be best
                                    for your brand.
                                </p>
                                <div class="row">
                                    <a href="/contact" class="btn btn-primary servicesContactUs">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
    </div>
</section>