<section class="subscribe-area ptb-100">
    <div class="container">
        <div class="newsletter">
            <h2>Join Our Newsletter</h2>
            <form class="newsletter-form">
                <input type="email" class="form-control" placeholder="Your Email Address">
                <button class="btn btn-primary" type="submit">Subscribe</button>
            </form>
        </div>
    </div>
</section>