import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-website-design',
  templateUrl: './website-design.component.html',
  styleUrls: ['./website-design.component.scss']
})
export class WebsiteDesignComponent implements OnInit {

  constructor(private _title: Title,
    private _metaService: Meta) {
  }

  ngOnInit() {
    this._title.setTitle("NGraphica - Best website Design Company in Pune");

    this._metaService.addTag({
      name: 'keywords',
      content: 'website Designer, static website Design in Pune, dynamic website Design in Pune, Logo Design Company in Pune, Stationary Design Company, Corporate Guidelines Manual, Corporate Profile Design, Corporate Identity, Attractive Logo design, innovative logo design, creative logo design'
    })
    this._metaService.addTag({
      name: 'description',
      content: 'Our attractive and outstanding service always perform the proper manner of logo design service, our expertise in promoting the brand through the logo design.'
    })
    this._metaService.addTag({
      name: 'author',
      content: 'NGraphica'
    })
  }

}
