<section id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>Privacy Policy @ NGraphica</h4>
            <h2>Welcome to <span>N</span>Graphica</h2>
            <p><span>BRANDING</span> / <span> DESIGN </span> /
                <span>DIGITAL MARKETING</span> / <span>WEBSITE DEVELOPMENT</span>
            </p>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <p>This page informs you of our policies regarding the collection, use and disclosure of Personal
                    Information when you use our Service.
                </p><br />

                <p>We will not use or share your information with anyone except as described in this Privacy Policy.
                </p><br />

                <p>We use your Personal Information for providing and improving the Service. By using the Service, you
                    agree to the collection and use of information in accordance with this policy. Unless otherwise
                    defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our
                    Terms and Conditions, accessible at <a class="terms-and-coniditons-link" href="https://ngraphica.com/terms">ngraphica.com</a>
                </p><br />

                <h4 class="privacy-policy-section-title">Information Collection And Use</h4>

                <p>While using our Service, we may ask you to provide us with certain personally identifiable
                    information that can be used to contact or identify you. Personally identifiable information may
                    include, but is not limited to, your email address, name, phone number, postal address, other
                    information ("Personal Information").
                </p><br />

                <h4 class="privacy-policy-section-title">Log Data</h4>

                <p>We may also collect information that your browser sends whenever you visit our Service ("Log Data").
                    This Log Data may include information such as your computer's Internet Protocol ("IP") address,
                    browser type, browser version, the pages of our Service that you visit, the time and date of your
                    visit, the time spent on those pages and other statistics.
                </p><br />

                <p>In addition, we may use third party services such as Google Analytics that collect, monitor and
                    analyze this type of information in order to increase our Service's functionality. These third party
                    service providers have their own privacy policies addressing how they use such information.
                </p><br />

                <h4 class="privacy-policy-section-title">Cookies</h4>
                <p>
                    Cookies are files with small amount of data, which may include an anonymous unique identifier.
                    Cookies are sent to your browser from a web site and stored on your computer's hard drive.
                </p><br />

                <p>
                    We use "cookies" to collect information. You can instruct your browser to refuse all cookies or to
                    indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to
                    use some portions of our Service.
                </p><br />

                <h4 class="privacy-policy-section-title">Behavioral Remarketing</h4>
                <p>NGraphica uses remarketing services to advertise on third party web sites to you after you visited
                    our Service. We, and our third party vendors, use cookies to inform, optimize and serve ads based on
                    your past visits to our Service.</p><br />

                <h4 class="privacy-policy-section-title">Service Providers</h4>
                <p>We may employ third party companies and individuals to facilitate our Service, to provide the Service
                    on our behalf, to perform Service-related services or to assist us in analyzing how our Service is
                    used.
                </p><br />

                <p>These third parties have access to your Personal Information only to perform these tasks on our
                    behalf and are obligated not to disclose or use it for any other purpose.
                </p><br />

                <h4 class="privacy-policy-section-title">Security</h4>
                <p>The security of your Personal Information is important to us, but remember that no method of
                    transmission over the Internet, or method of electronic storage is 100% secure. While we strive to
                    use commercially acceptable means to protect your Personal Information, we cannot guarantee its
                    absolute security.</p><br />

                <h4 class="privacy-policy-section-title">International Transfer</h4>
                <p>Your information, including Personal Information, may be transferred to — and maintained on —
                    computers located outside of your state, province, country or other governmental jurisdiction where
                    the data protection laws may differ than those from your jurisdiction.</p><br />

                <p>If you are located outside India and choose to provide information to us, please note that we
                    transfer the information, including Personal Information, to India and process it there.</p><br />

                <p>Your consent to this Privacy Policy followed by your submission of such information represents your
                    agreement to that transfer.</p><br />


                <h4 class="privacy-policy-section-title">Links to Other Sites</h4>

                <p>Our Service may contain links to other sites that are not operated by us. If you click on a third
                    party link, you will be directed to that third party's site. We strongly advise you to review the
                    Privacy Policy of every site you visit.</p><br />

                <p>We have no control over, and assume no responsibility for the content, privacy policies or practices
                    of any third party sites or services.
                </p><br />


                <h4 class="privacy-policy-section-title">Children's Privacy</h4>
                <p>Our Service does not address anyone under the age of 13 ("Children").</p><br />

                <p>We do not knowingly collect personally identifiable information from children under 13. If you are a
                    parent or guardian and you are aware that your Children has provided us with Personal Information,
                    please contact us. If we become aware that we have collected Personal Information from a children
                    under age 13 without verification of parental consent, we take steps to remove that information from
                    our servers.</p><br />


                <h4 class="privacy-policy-section-title">Changes To This Privacy Policy</h4>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the
                    new Privacy Policy on this page.</p><br />

                <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                    Policy are effective when they are posted on this page.</p><br />

                <p>Sincerely, </p>
                <p>NGraphica.com</p>
            </div>

        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</section>