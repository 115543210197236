import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-exhibition-panel-designs',
  templateUrl: './exhibition-panel-designs.component.html',
  styleUrls: ['./exhibition-panel-designs.component.scss']
})
export class ExhibitionPanelDesignsComponent implements OnInit {

  constructor(private _title: Title,
    private _metaService: Meta) {
  }

  ngOnInit() {
    this._title.setTitle("Best Exhibition Panel Design & Installation Company in Pune - NGraphica");

    this._metaService.addTag({
      name: 'keywords',
      content: 'Exhibition design, exhibition panel design template, exhibition design concept, exhibition display ideas and design, exhibition panels, exhibition stand design, exhibition panel size, exhibition stall design, didactic panel design'
    })
    this._metaService.addTag({
      name: 'description',
      content: 'NGraphica is the best exhibition panel design company of Pune. Exhibition panel design and attractive stand design are very useful in attracting maximum attention of visitors.'
    })
    this._metaService.addTag({
      name: 'author',
      content: 'NGraphica'
    })
  }

}
