import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, share } from 'rxjs/operators';
import { FormArray } from '@angular/forms';
import { ContactUs } from './../models/contactUs';

@Injectable({
    providedIn: 'root'
})
export class HomePageService {

    apiUrl: string;
    constructor(private http: HttpClient) {
        this.apiUrl = "https://ke37xa7lce.execute-api.ap-south-1.amazonaws.com/beta/submit";
    }

    public isHomePage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    getIsHomePage = this.isHomePage.asObservable();
    setIsHomePage(data) {
        this.isHomePage.next(data);
    }

    public selectedMenu: BehaviorSubject<boolean> = new BehaviorSubject<any>('');
    getSelectedMenu = this.selectedMenu.asObservable();
    setSelectedMenu(data) {
        this.selectedMenu.next(data);
    }

    public sendInquiry(mailFormObj: ContactUs): Observable<Response> {
        return this.http.post<Response>(this.apiUrl, mailFormObj)
    }

}