<section class="who-we-are-area pt-100 pb-70 margin-top-20">
    <div class="container">
        <div class="section-title">
            <h2>Advertisement <span>Campaigns</span></h2>
            <div class="servicesTagLine">
                <span>MAGAZINE ADVERTISE DESIGN </span> / <span> NEWSPAPER ADVERTISE DESIGN </span> /
                <span>HOARDINGS ADVERTISE DESIGN </span> / <span>DIGITAL ADS</span>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="strategy-content">
                    <div class="section-title">
                        <!-- <div class="row" style="margin-bottom: 25px;">
                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-cog fa-3x" aria-hidden="true"></i>
                                    <h6>Magazine Advertise Design </h6>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-eye fa-3x" aria-hidden="true"></i>
                                    <h6>Newspaper Advertise Design</h6>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-book fa-3x" aria-hidden="true"></i>
                                    <h6>Hoardings Advertise Design</h6>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 servicesSectionText">
                                <div class="fes6-box wow fadeIn">
                                    <i class="fa fa-cog fa-3x" aria-hidden="true"></i>
                                    <h6>Digital Ads</h6>
                                </div>
                            </div>
                        </div> -->
                        <span class="sectionText">We are professional Logo and Brochure Designers in Pune, offering
                            premium custom logo design and Branding services to startups, small and medium size
                            businesses. We convey your company message, colors, fonts, logo, emotions & target audience
                            through our branding service. We create unique branding concept for you business. Your
                            branding is set you apart from the competition & allow your customers to differentiate from
                            your competitors.</span>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="section-title">
                                <h4>Advertisement <span>Campaigns</span></h4>
                            </div>
                            <div class="about-content">
                                <p>Its an Art they say! and yes we at NGraphica have been blessed by it. An advertising
                                    campaign is a specifically designed
                                    strategy that is carried out across different mediums in order to both achieve
                                    results and to increase brand awareness,
                                    sales and communication within a specific market. All of this is accomplished
                                    through advertising. NGraphica is an
                                    advertising agency in Pune with extensive experience in the market.</p>
                                <div class="row">
                                    <a href="/contact" class="btn btn-primary servicesContactUs">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="about-video">
                                <img src="assets/img/advertisement-campaigns.jpg" alt="about">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="about-video">
                                <img src="assets/img/corporate-profile.jpg" alt="about">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="section-title">
                                <h4>Corporate <span>Profile</span></h4>
                            </div>
                            <div class="about-content">
                                <p>A Perfect Corporate Profile to give it new wings to fly high, We design your new
                                    identity and a brand new face. The
                                    Corporate Profile of your association encourages you to stand out from the crowd and
                                    spread your message in the most
                                    refined and comprehensible manner as possible. Our Corporate Profile design attracts
                                    the targeted audience for your
                                    business. and helps to Conveying an understanding of the Products/Services and
                                    gaining popularity.</p>
                                <div class="row">
                                    <a href="/contact">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
    </div>
</section>