<section class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Who We <span>Are</span></h2>
            <span style="color: #727695;">Defining the problems and opportunities, researching the client and the
                competition, analysing the market
                conditions,
                collecting relevant data, probing into different situations, raising questions, checking on new
                possibilities, fixing
                the timelines, predicting the outcome; what may sound as a scientific methodology is actually the
                communication
                development and delivery process in this agency, that for good reasons, is called the NGraphica.</span>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h1>We are professional</h1>
                    <span>1</span>
                    <div class="card-border"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-lightbulb"></i>
                    <h1>We are passionate</h1>
                    <span>2</span>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-tag"></i>
                    <h1>We are creative</h1>
                    <span>3</span>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-hand-scissors"></i>
                    <h1>We are designer</h1>
                    <span>4</span>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fab fa-linode"></i>
                    <h1>We are unique</h1>
                    <span>5</span>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-life-ring"></i>
                    <h1>We are supportive</h1>
                    <span>6</span>
                </div>
            </div>
        </div>
    </div>
</section>