import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { environment } from './../environments/environment';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})

export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;
    gtag: any;

    constructor(private router: Router) {
        // const navEndEvent$ = router.events.pipe(
        //     filter(e => e instanceof NavigationEnd)
        // );
        // navEndEvent$.subscribe((e: NavigationEnd) => {
        //     gtag('config', 'UA-182356075-1', { 'page_path': e.urlAfterRedirects });
        // });

    }

    ngOnInit() {
        this.recallJsFuntions();
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.code;
        document.head.prepend(script);
    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    $('.preloader').fadeIn('veryslow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/main.js');
                $('.preloader').fadeOut('veryslow');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}