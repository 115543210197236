<section id="contact" class="services-area pt-100 pb-70 margin-top-20">
    <div class="container">
        <div class="section-title">
            <h4>Get in Touch</h4>
            <h2>Let's <span>Contact</span> Us</h2>
        </div>

        <div class="row">
            <div class="col-lg-5 col-md-12">

                <!-- <app-map></app-map> -->

                <div class="contact-info">
                    <ul>
                        <li><i class="fas fa-map-marker-alt"></i>
                            <strong>N Graphica</strong>
                            <br>
                            Pune.<br>
                        </li>
                        <li><i class="fas fa-envelope"></i>
                            <a href="mailto:ngraphica23@gmail.com">
                                ngraphica23@gmail.com
                            </a>
                        </li>
                        <!-- <li><i class="fas fa-envelope"></i>
                            <a href="mailto:ngraphica23@gmail.com">
                                ngraphica23@gmail.com
                            </a></li> -->
                        <!-- <li><i class="fas fa-mobile"></i>
                            <a href="tel:+917020237795">+91 7020237795</a></li>
                        <li><i class="fas fa-mobile"></i>
                            <a href="tel:+917875361602">+91 7875361602</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <h3>Stay Connected</h3>
                    <form [formGroup]="contactInfoForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>First Name</label>
                                    <input type="text" formControlName="FirstName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }" />
                                    <div *ngIf="submitted && f.FirstName.errors" class="invalid-feedback">
                                        <div *ngIf="f.FirstName.errors.required">First Name is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Last Name</label>
                                    <input type="text" formControlName="LastName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }" />
                                    <div *ngIf="submitted && f.LastName.errors" class="invalid-feedback">
                                        <div *ngIf="f.LastName.errors.required">Last Name is required
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="text" formControlName="email" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Phone Number</label>
                                    <input NumericOnly formControlName="Phone" maxlength="10" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.Phone.errors }" />
                                    <div *ngIf="submitted && f.Phone.errors" class="invalid-feedback">
                                        <div *ngIf="f.Phone.errors.required">Phone is required
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Message</label>
                                    <span class="msgInfoText">{{messageDescp.value.length}}/950</span>
                                    <textarea class="form-control" #messageDescp formControlName="Message" cols="30"
                                        rows="4" [ngClass]="{ 'is-invalid': submitted && f.Message.errors }"></textarea>
                                    <div *ngIf="submitted && f.Message.errors" class="invalid-feedback">
                                        <div *ngIf="f.Message.errors.required">Message is required
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button (click)="saveContactInfo()" class="btn btn-primary"
                                    style="margin-right: 10px;">Send Message</button>
                                <button (click)="resetContactInfo()" class="btn btn-primary">Reset</button>
                            </div>
                            <div class="col-lg-12 col-md-12 alert alert-success mt-20" *ngIf="showMessage">
                                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                <strong>{{successMessage}}</strong>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
    </div>
</section>