import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomePageService } from 'src/app/services/checkHomePage.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home-eleven',
  templateUrl: './home-eleven.component.html',
  styleUrls: ['./home-eleven.component.scss']
})
export class HomeElevenComponent implements OnInit {

  constructor(private router: Router,
    private _homePageService: HomePageService,
    private _title: Title,
    private _metaService: Meta) {
  }

  ngOnInit() {
    this._homePageService.setIsHomePage(true);

    this._title.setTitle("NGraphica - Corporate Logo and Brochure Designing Company in Pune");

    this._metaService.addTag({
      name: 'keywords',
      content: 'Best Logo Designing company Pune Brochure Designing, Packaging Design Service, Corporate Branding, Corporate Logo Design, Company Profile PPT, Leaflet Design Service in Pune, SEO in Pune, Social Media Marketing in Pune, Marketing Strategy Consultancy, Print Media, Flyer Design Service in Pune, Vertical Leaflet, Innovative Leaflet'
    })
    this._metaService.addTag({
      name: 'description',
      content: 'NGraphica, the dynamic graphics and branding agency and one solution for all designing problems. Our service to establishing a successful brand with the help of unique designs.'
    })
    this._metaService.addTag({
      name: 'author',
      content: 'NGraphica'
    })
  }

}
