<section id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>We are creative</h4>
            <h2>Welcome to <span>N</span>Graphica</h2>
            <p><span>BRANDING</span> / <span> DESIGN </span> /
                <span>DIGITAL MARKETING</span> / <span>WEBSITE DEVELOPMENT</span></p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Creative Design</h3>
                    <p>Creative design includes designs that are unique, effective and memorable. I’d say a creative
                        design usually involves
                        going out of your way to make it unique, whether that be creating a handmade custom font rather
                        than using a generic
                        one, drawing a logo by hand and then converting it to vector, or going one step further past the
                        “obvious” iconography
                        to really help your client stand out in their industry. It takes practice, and definitely more
                        than a couple drafts on
                        each design in order to get to this point!</p>
                    <a href="/about" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-laptop"></i>
                    </div>
                    <h3>Development</h3>
                    <p>A website for your small business is a necessity, not a luxury. Whether you are a beauty clinic,
                        a candlemaker, a yoga
                        practitioner, a fitness center, or a sports instructor, an attractive, responsive website design
                        can help you reach more
                        customers. Small Business Web offers comprehensive web design and development services that will
                        help your small
                        business website reach maximum customers. We also provides Web site maintenance services to
                        maintain and keep your site
                        up to the latest Web standards.</p>
                    <a href="/about" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-life-ring"></i>
                    </div>
                    <h3>Fast Support</h3>
                    <p>We at NGraphica, considering the need for a business that we are into, provides 24/7 support to
                        improve its customer support, it ensures 100% uptime of websites. Web Design Company Pune
                        provides 24×7 support for our clients.</p>
                    <a href="/about" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</section>