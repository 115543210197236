<nav class="navbar navbar-expand-lg navbar-light bg-light header-sticky" [ngClass]="isSticky?'':'is-sticky'">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <span style="text-transform: uppercase;">n</span>Graphica
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a class="nav-link" href="/home" routerLinkActive="active">Home</a></li>
                <li class="nav-item"><a class="nav-link" href="/about" routerLinkActive="active">About</a></li>
                <li class="nav-item"><a class="nav-link" href="/services" routerLinkActive="active">Services</a>
                </li>
                <li class="nav-item"><a class="nav-link" href="/how-we-work" routerLinkActive="active">How We
                        Work</a></li>
                <!-- <li class="nav-item"><a class="nav-link" href="/work" routerLinkActive="active">Work</a></li> -->
                <!-- <li class="nav-item"><a class="nav-link" href="/team" routerLinkActive="active">Team</a></li> -->
                <li class="nav-item"><a class="nav-link" href="/contact" routerLinkActive="active">Contact</a>
                </li>
            </ul>
        </div>
    </div>
</nav>